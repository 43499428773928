<template>
    <div class="text-gray-900">
        <p class=" font-normal text-sm antialiased block my-4">
      We are working on providing this new feature within the next releases !
    </p>
    </div>
</template>

<script setup>

</script>

<style lang="scss" scoped></style>