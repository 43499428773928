<template>
    <header>
        <LandingPageHeader />
        <div class="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 mt-16">
            <div class="sm:mx-auto sm:w-full sm:max-w-sm">
                <img class="mx-auto h-10 w-auto" src="@/assets/artificial-intelligence.png"
                    alt="Your Company" />
                <h2 class="mt-10 text-center text-2xl font-base leading-9 tracking-tight text-gray-900">Sign in to your account
                </h2>
            </div>
            <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                <form class="space-y-6" @submit.prevent="handleSubmit">
                    <div>
                        <label for="email" class="block text-sm font-medium leading-6 text-gray-900">Email address</label>
                        <div class="mt-2">
                            <input v-model="email" id="email" name="email" type="email" autocomplete="email" required=""
                                class="block w-full rounded-md border-0 bg-white/5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6" />
                        </div>
                    </div>
                    <div>
                        <div class="flex items-center justify-between">
                            <label for="password" class="block text-sm font-medium leading-6 text-gray-900">Password</label>
                            <!-- <div class="text-sm">
                    <a href="#" class="font-semibold text-indigo-400 hover:text-indigo-300">Forgot password?</a>
                  </div> -->
                        </div>
                        <div class="mt-2">
                            <input v-model="password" id="password" name="password" type="password"
                                autocomplete="current-password" required=""
                                class="block w-full rounded-md border-0 bg-white/5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6" />
                        </div>
                    </div>
                    <div v-if="error" class="text-red-500 text-sm">
                        {{ error }}
                    </div>
                    <div>
                        <button type="submit"
                            class="flex w-full justify-center rounded-md bg-indigo-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">Sign
                            in</button>
                    </div>
                    <div>
                        <button @click="handleGoogleLogin()"
                            class="flex w-full items-center justify-center bg-white dark:bg-white border border-gray-300 rounded-lg shadow-md px-6 py-2 text-sm font-medium text-gray-800 dark:text-gray hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                            <svg class="h-6 w-6 mr-2" xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink" width="800px" height="800px"
                                viewBox="-0.5 0 48 48" version="1.1">
                                <title>Google-color</title>
                                <desc>Created with Sketch.</desc>
                                <defs> </defs>
                                <g id="Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <g id="Color-" transform="translate(-401.000000, -860.000000)">
                                        <g id="Google" transform="translate(401.000000, 860.000000)">
                                            <path
                                                d="M9.82727273,24 C9.82727273,22.4757333 10.0804318,21.0144 10.5322727,19.6437333 L2.62345455,13.6042667 C1.08206818,16.7338667 0.213636364,20.2602667 0.213636364,24 C0.213636364,27.7365333 1.081,31.2608 2.62025,34.3882667 L10.5247955,28.3370667 C10.0772273,26.9728 9.82727273,25.5168 9.82727273,24"
                                                id="Fill-1" fill="#FBBC05"> </path>
                                            <path
                                                d="M23.7136364,10.1333333 C27.025,10.1333333 30.0159091,11.3066667 32.3659091,13.2266667 L39.2022727,6.4 C35.0363636,2.77333333 29.6954545,0.533333333 23.7136364,0.533333333 C14.4268636,0.533333333 6.44540909,5.84426667 2.62345455,13.6042667 L10.5322727,19.6437333 C12.3545909,14.112 17.5491591,10.1333333 23.7136364,10.1333333"
                                                id="Fill-2" fill="#EB4335"> </path>
                                            <path
                                                d="M23.7136364,37.8666667 C17.5491591,37.8666667 12.3545909,33.888 10.5322727,28.3562667 L2.62345455,34.3946667 C6.44540909,42.1557333 14.4268636,47.4666667 23.7136364,47.4666667 C29.4455,47.4666667 34.9177955,45.4314667 39.0249545,41.6181333 L31.5177727,35.8144 C29.3995682,37.1488 26.7323182,37.8666667 23.7136364,37.8666667"
                                                id="Fill-3" fill="#34A853"> </path>
                                            <path
                                                d="M46.1454545,24 C46.1454545,22.6133333 45.9318182,21.12 45.6113636,19.7333333 L23.7136364,19.7333333 L23.7136364,28.8 L36.3181818,28.8 C35.6879545,31.8912 33.9724545,34.2677333 31.5177727,35.8144 L39.0249545,41.6181333 C43.3393409,37.6138667 46.1454545,31.6490667 46.1454545,24"
                                                id="Fill-4" fill="#4285F4"> </path>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                            <span>Continue with Google</span>
                        </button>
                    </div>
                </form>
                <p class="mt-10 text-center text-sm text-gray-900">
                    Not a member?
                    {{ ' ' }}
                    <router-link :to="{ name: 'Signup' }" href="#"
                        class="font-semibold leading-6 text-indigo-500 hover:text-indigo-400">Sign up for a free
                        trial</router-link>
                </p>
            </div>
        </div>
    </header>
</template>
<script setup>
import LandingPageHeader from '@/components/landing-page/LandingPageHeader.vue'
import useLogin from "@/composables/useLogin";
import { projectAuth } from '@/firebase/config';
import { ref } from "@vue/reactivity";
import { useRouter } from "vue-router";
import useCollection from "@/composables/useCollection";
import User from '@/model/User';
import { projectFirestore } from '@/firebase/config';

const { error, login, singInWithGoogle, isPending } = useLogin();
const email = ref("");
const password = ref("");
const router = useRouter();

const handleSubmit = async () => {
    const res = await login(email.value, password.value);
    if (!error.value) {
        router.push({ name: "Home" });
    }
};

const handleGoogleLogin = async () => {
    await singInWithGoogle();

};
function _createNewUserIfnotExists(displayName, email, uid) {
    const { addDocWithCustomId } = useCollection('users')
    if (!projectFirestore.collection('users').doc(uid).get().exists) {
        let user = new User(displayName, email, uid, 20, false)
        try {
            addDocWithCustomId({
                ...user
            }, uid)
        } catch (error) {
            console.log(error);
        }

    }
}
projectAuth.getRedirectResult().then(function (result) {
    var user = result.user;
    if (user) {
        // User not logged in, start login.
        // firebase.auth().signInWithRedirect(provider);
        var user = result.user;
        _createNewUserIfnotExists(user.displayName, user.email, user.uid)
        router.push({ name: "Home" });
    }
}).catch(function (error) {
    // Handle Errors here.
    console.log(error)
    // ...
});

</script>
  