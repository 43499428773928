<template>
  <!-- company presentation -->
  <div class="sm:py-6 sm:px-8 md:px-16 lg:px-48 px-4">
    <header class="flex items-center gap-x-3 align-middle border-b border-white/5 mb-16">
      <a :href="companyWebsite" target="_blank" class="text-3xl font-bold leading-7 text-gray-900">{{ companyName }}</a>
      <a class="flex items-center" :href="linkedInWebsite" target="_blank" v-show="typeof linkedInWebsite !== 'undefined'">
          <div class="button">
            <BsLinkedin class="icon" color="#0072b1" />
          </div>
        </a>
        <a class="flex items-center" :href="crunchbaseWebsite" target="_blank" v-show="typeof crunchbaseWebsite !== 'undefined'">
          <div class="button">
            <CoBrandCrunchbase class="icon" color="#1669ff" />
          </div>
        </a>
        <!-- Readiness badge  -->
    <div v-show="isReportReady !=='1'"
        :class="[statusIcons[isReportReady], ' rounded-full flex-none py-1 px-2 text-xs font-medium ring-1 ring-inset w-fit']">
        {{ readiness[isReportReady] }}</div>
    </header>

    

    <!-- tabs  -->
    <div class="">
      <div class="border-b border-gray-200 pb-5 sm:pb-0">
        <div class="mt-3 sm:mt-4">
          <!-- <div class="sm:hidden">
            <label for="current-tab" class="sr-only">Select a tab</label>
            <select id="current-tab" name="current-tab"
              class="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
              <option v-for="tab in tabs" :key="tab.name" :selected="tab.current">{{ tab.name }}</option>
            </select>
          </div> -->
          <div class="hidden sm:block">
            <nav class="-mb-px flex space-x-8">
              <a v-for="tab in tabs" :key="tab.name" @click="activateTab(tab)"
                :class="[tab.current ? 'border-indigo-500 text-indigo-500' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700', 'gray-900space-nowrap border-b border-gray-300 px-1 pb-4 text-sm font-medium cursor-pointer']">
                {{ tab.name }}</a>
            </nav>
          </div>
        </div>
      </div>
    </div>
    <!-- tab content -->
    <Report v-if="tabs[0].current" :id="id" collection_name="reports_langchain_v2" />
    <Stats v-else-if="tabs[1].current" :id="id" collection_name="reports_langchain_v2" />
  </div>

  <!-- <div class="flex flex-row space-x-1 mb-2 mt-5">
      <a :href="companyWebsite">
        <div class="hover:shadow">
          <img class="w-16 h-16 rounded-sm" v-bind:src="companyThumbnail" alt="" />
        </div>
      </a>
      <h1 class="text-zinc-800 font-light sm:text-6xl">
        {{ companyName }}
      </h1>
    </div> -->

  <!-- <div class="flex-row mt-2">
      <a :href="linkedInWebsite" v-show="typeof linkedInWebsite !== 'undefined'">
        <div class="button">
          <BsLinkedin class="icon" color="#0072b1" />
        </div>
      </a>
      <a :href="crunchbaseWebsite" v-show="typeof crunchbaseWebsite !== 'undefined'">
        <div class="button">
          <CoBrandCrunchbase class="icon" color="#1669ff" />
        </div>
      </a>
    </div>

  <div class="text-zinc-800 font-light" v-if="!reportIsPending">
    <section class="px-5">
      <div v-if="isEditable" class="py-4 mt-2">
        <QuillEditor theme="snow" v-model:content="deltaObject" :contentType="delta" />
      </div>
      <div v-else class="py-4 mt-8">
        <SimpleOutput :finalReport="finalReport" />
      </div>
      <div class="text-right">
        <div v-if="isEditable">
          <Button text="Save" :disabled="false" @click="handleSave" />
        </div>
        <div v-else#>
          <ButtonInverted text="Edit" :disabled="false" @click="handleEdit" />
        </div>
      </div>
    </section>


    <div class="mt-10 py-5 px-5 shadow-inner">
      <ChatWindow :pitchdeckMetadata="pitchdeckMetadata" />
    </div>

  </div>

  <div v-else class="text-zinc-800 mt-4 font-light px-5">
    The report is not ready yet... Please wait a few minutes
  </div> -->
</template>

<script setup>
import getDocument from "@/composables/getDocument";
import { ref, watch, onMounted } from "vue";
import useDocument from "@/composables/useDocument";
import { projectFirestore } from "@/firebase/config";
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import { transformJson } from "@/composables/helpers.js";
import Delta from "quill-delta";
import { BsLinkedin } from "@kalimahapps/vue-icons";
import { CoBrandCrunchbase } from "@kalimahapps/vue-icons";
import Report from "@/components/report/Report.vue";
import Stats from "@/components/report/Stats.vue";



const props = defineProps({
  id: String,
  fetchFunction: String,
  collection_name: String,
})


const { document: pitchdeckMetadata, error: fetchError } = getDocument(
  "pitchdecks",
  props.id
);

const isReportReady = ref("0")
const readiness = {
  "-1": 'Error',
  "0": 'Processing...',
  "1": 'Ready',
}
const statusIcons = {
  "-1": 'text-red-400 bg-red-400/10 ring-red-400/20',
  "0": 'text-gray-400 bg-gray-400/10 ring-gray-400/20',
  "1": 'text-green-400 bg-green-400/10 ring-green-400/30',
}


const companyThumbnail = ref("");
const companyName = ref(null);
const companyWebsite = ref("");
const crunchbaseWebsite = ref(undefined)
const linkedInWebsite = ref(undefined)
const reportIsPending = ref(true);
const finalReport = ref("");

const questionAnswerList = ref([
  { question: "What is your name?", answer: "My name is..." },
]);
const deltaObject = ref(new Delta())
const deltaObjectExists = ref(false)
const isEditable = ref(false);

watch(pitchdeckMetadata, async () => {
  try {
    isReportReady.value = pitchdeckMetadata.value.isReportReady
    companyWebsite.value = pitchdeckMetadata.value.companyWebsite;
    companyThumbnail.value = pitchdeckMetadata.value.companyThumbnail;
    companyName.value = pitchdeckMetadata.value.companyName;
    [crunchbaseWebsite.value] = pitchdeckMetadata.value.companyCrunchbaseLinks || [];
    [linkedInWebsite.value] = pitchdeckMetadata.value.companyLinkedinLinks || [];
    // console.log(linkedInWebsite.value)
    let deltaObjectContent = await projectFirestore.collection("reports_langchain_v2_delta_format").doc(pitchdeckMetadata.value.companyName).get()
    // console.log(deltaObjectContent)
    if (deltaObjectContent.exists === true) {
      deltaObjectExists.value = true;
      deltaObject.value = new Delta(deltaObjectContent.data())
      reportIsPending.value = false;
      isEditable.value = true;
    }
    else {
      let getFinalReportData = await projectFirestore
        .collection("reports_langchain_v2")
        .doc(pitchdeckMetadata.value.companyName)
        .get();
      const { createdAt, userId, ...buff } = getFinalReportData.data();
      finalReport.value = buff;
      deltaObject.value = transformJson(finalReport.value)
      reportIsPending.value = false;
    }

  } catch (err) {
    console.log(err);
  }
});

function handleSave() {
  try {
    if (deltaObjectExists.value === false) {
      projectFirestore.collection("reports_langchain_v2_delta_format").doc(pitchdeckMetadata.value.companyName).set(JSON.parse(JSON.stringify(deltaObject.value)))
    }
    else {
      const { updateDoc } = useDocument(
        "reports_langchain_v2_delta_format",
        pitchdeckMetadata.value.companyName
      );
      updateDoc(JSON.parse(JSON.stringify(deltaObject.value)));
    }

  } catch (err) {

    console.log(err)
  }
}

function handleEdit() {
  isEditable.value = true;
}

function onQuestionAnswered(obj) {
  questionAnswerList.value.push(obj);
}

const tabs = ref([
  { name: 'Report', href: '#', current: true },
  //{ name: 'Stats', href: '#', current: false },
])

function activateTab(selection) {
  tabs.value.forEach(tab => {
    tab.current = false
  })
  selection.current = true
}


</script>

<style scoped lang="scss">
.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  // padding: 0.25rem 0.25rem;
  border-radius: 0.125rem;
  color: #000000;
  cursor: pointer;

  /* Change color and size of the icon */
  .icon {
    font-size: 1.5em;

    // &:hover {
    //   box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    //   /* Add shadow effect */
    // }
  }

}
</style>