<template>
    <div class="flex justify-end mb-4">
        <div class="flex flex-col">
            <div class="mr-2 py-3 px-4 bg-indigo-600 rounded-bl-3xl rounded-tl-3xl rounded-tr-xl text-white font-normal text-sm">
                {{ message.message }}
            </div>
            <div class="text-xs px-2 text-right">{{ message.createdAt }} ago</div>
        </div>

        <div class="flex items-center justify-center h-8 w-8 rounded-full bg-indigo-900">
            <p class="text-center text-white m-4">{{userTag}}</p>
        </div>

    </div>
</template>

<script setup>
import {computed} from 'vue'
const props= defineProps({
    message: Object,
    userDisplayName: String,
    userPhotoUrl: String
})  


const userTag = computed(()=>{
    return props.userDisplayName[0]
})
</script>

<style lang="scss" scoped></style>