export default class User{
    constructor(displayName, email, userId, credits, paid){
        this.displayName = displayName
        this.email = email
        this.userId = userId
        this.credits = credits
        this.paid = paid
        this.sessionId = []

    }
}