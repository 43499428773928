<template>
  <div class="text-gray-900">
    <p class="text-gray-900 text-normal font-light antialiased block py-4 px-8 my-6">
    <div>
      <div class="px-4 sm:px-0">
        <h3 class="text-base font-bold leading-7 text-gray-900">Account Information</h3>
      </div>
      <div class="mt-6 border-t border-white/10">
        <dl class="divide-y divide-white/10">
          <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt class="text-sm font-bold leading-6 text-gray-900">Full name</dt>
            <dd class="mt-1 text-sm leading-6 font-normal text-gray-900 sm:col-span-2 sm:mt-0">{{ userObject.displayName }}</dd>
          </div>
          <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt class="text-sm font-bold leading-6 text-gray-900">Email address</dt>
            <dd class="mt-1 text-sm leading-6 text-gray-900 sm:col-span-2 sm:mt-0 font-normal">{{ userObject.email }}</dd>
          </div>
          <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt class="text-sm font-bold leading-6 text-gray-900">Available credits</dt>
            <dd class="mt-1 text-sm leading-6 text-gray-900 sm:col-span-2 sm:mt-0 font-normal">{{ userObject.credits }}</dd>
          </div>

        </dl>
      </div>
    </div> 
    <!-- Payment successfull -->
    <div v-if="paid" class="text-gray-900">
      <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-10" @close="open = false">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
              <div>
                <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                  <CheckIcon class="h-6 w-6 text-green-600" aria-hidden="true" />
                </div>
                <div class="mt-3 text-center sm:mt-5">
                  <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">Payment successful</DialogTitle>
                  <div class="mt-2">
                    <p class="text-sm text-gray-500">You might need to refresh the page to see your updated credits.</p>
                  </div>
                </div>
              </div>
              <div class="mt-5 sm:mt-6">
                <button type="button" class="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm hover:bg-indigo-500 focus-visible:outline " @click="open = false">Go back to dashboard</button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
    
    </div>

    <!-- put Below is the pricing description -->
    <div class="bg-white py-6 sm:py-8">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-4xl text-center">
        <p class="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">Available pricing plans</p>
      </div>
      <p class="mx-auto mt-6 max-w-2xl text-center text-lg font-normal leading-8 text-gray-900">Currently we only support pay per usage. We will introduce subscription based plans very soon!</p>
      <!-- <div class="mt-16 flex justify-center">
        <RadioGroup v-model="frequency" class="grid grid-cols-2 gap-x-1 rounded-full bg-gray-900/5 p-1 text-center text-xs font-semibold leading-5 text-gray-900">
          <RadioGroupLabel class="sr-only">Payment frequency</RadioGroupLabel>
          <RadioGroupOption as="template" v-for="option in frequencies" :key="option.value" :value="option" v-slot="{ checked }">
            <div :class="[checked ? 'bg-indigo-500' : '', 'cursor-pointer rounded-full px-2.5 py-1']">
              <span>{{ option.label }}</span>
            </div>
          </RadioGroupOption>
        </RadioGroup>
      </div> -->
      <div class="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-2">
        <div v-for="tier in tiers" :key="tier.id" :class="[tier.mostPopular ? 'bg-white/5 ring-2 ring-indigo-500' : 'ring-1 ring-white/10', 'rounded-3xl p-8 xl:p-10']">
          <div class="flex items-center justify-between gap-x-4">
            <h3 :id="tier.id" class="text-lg font-semibold leading-8 text-gray-900">{{ tier.name }}</h3>
            <p v-if="tier.mostPopular" class="rounded-full bg-indigo-500 px-2.5 py-1 text-xs font-semibold leading-5 text-white">Pay per use</p>
          </div>
          <p class="mt-4 text-sm leading-6 text-gray-900">{{ tier.description }}</p>
          <p class="mt-6 flex items-baseline gap-x-1">
            <span class="text-4xl font-bold tracking-tight text-gray-900">{{ tier.price[frequency.value] }}</span>
            <span class="text-sm font-semibold leading-6 text-gray-900">{{ frequency.priceSuffix }}</span>
          </p>
          <a @click="handleCheckout()" :aria-describedby="tier.id" :class="[tier.mostPopular ? 'bg-indigo-500 text-white shadow-sm hover:bg-indigo-400 hover:cursor-pointer focus-visible:outline-indigo-500' : 'bg-gray-900/10 text-gray-900 hover:bg-gray-900/20 focus-visible:outline-gray-900', 'mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2']">Buy credits</a>
          <ul role="list" class="mt-8 space-y-3 text-sm leading-6 text-gray-900 xl:mt-10">
            <li v-for="feature in tier.features" :key="feature" class="flex gap-x-3">
              <CheckIcon class="h-6 w-5 flex-none text-gray-900" aria-hidden="true" />
              {{ feature }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
    </p>



  </div>
</template>

<script setup>
import { projectFunctions } from "@/firebase/config";
import { useRoute } from 'vue-router'
import { ref } from "vue";
import { onMounted } from "vue";
import getUser from "@/composables/getUser";
import { projectFirestore } from "@/firebase/config";
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { CheckIcon } from '@heroicons/vue/20/solid'


const route = useRoute()
const createCheckoutSession = projectFunctions.httpsCallable("createCheckoutSession");
const getSessionStatus = projectFunctions.httpsCallable("getSessionStatus");
const paid = ref(null);
const session = ref(null);
const { user } = getUser();
const userObject = ref("")

const handleCheckout = async () => {
  await createCheckoutSession({ priceId: "price_1OPRKpHhJ1hMKynOIl2dSRjn" }).then(
    (result) => {
      console.log(result.data);
      window.location.href = result.data
    }
  ).catch((error) => {
    console.log(error);
  });
};

onMounted(async () => {
  const userDocument = await projectFirestore.collection('users').doc(user.value.uid).get()
  userObject.value = userDocument.data()
  if (route.query.session_id) {
    const sessionId = route.query.session_id
    await getSessionStatus({ sessionId: sessionId }).then(
      async (result) => {
        session.value = result.data;
        console.log(session.value);
        if (session.value.payment_status === "paid") {
          paid.value = true
          userDocument.data().paid ? userDocument.data().paid = true : userDocument.data().payed = true

          if (!userDocument.data().sessionsIds || !userDocument.data().sessionsIds.includes(sessionId)) {
            projectFirestore.collection('users').doc(user.value.uid).update({
              credits: userDocument.data().credits + 20,
              sessionsIds: userDocument.data().sessionsIds ? [...userDocument.data().sessionsIds, sessionId] : [sessionId]
            })
          }

        }
        else {
          paid.value = false
        }
      }
    ).catch((error) => {
      console.log(error);
    });

  }
})

// Payment plan
const frequencies = [
  
  { value: 'perUsage', label: 'per usage', priceSuffix: '/ 20 credits' },
  { value: 'monthly', label: 'Monthly', priceSuffix: '/month' },
]
const tiers = [
  {
    name: 'Freelancer',
    id: 'tier-freelancer',
    href: '#',
    price: { perUsage: '$20', monthly: '$30', annually: '$288' },
    description: 'A plan based on usage. Each report costs 1 credit.',
    features: [
      '20 reports',
      'Unlimited virtual assistant support',
      'Advanced analytics',
      '24-hour support response time',
    ],
    mostPopular: true,
  }
]

const frequency = ref(frequencies[0])

// Payment successfull
const open = ref(true)


</script>

<style></style>