<template>
    <div v-show="true">
        <div class="py-5">
            <input
                class="w-full placeholder-gray-500 placeholder-font-light rounded-md bg-white/5 ring-1 ring-inset ring-white/20 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500 font-normal"
                type="text" placeholder="Ask cortAIx..." v-model="question" @keypress.enter.prevent="handleSubmit" />
        </div>
        <div class="relative">
        </div>
    </div>
</template>


<script setup>
import getUser from '@/composables/getUser'
import { timestamp } from '@/firebase/config'
import { projectFunctions } from "@/firebase/config";
import { ref } from 'vue'
import useCollection from '@/composables/useCollection';
import { isBotLoading } from './loader.js'
import { onMounted, defineProps, defineEmits } from 'vue'


const props = defineProps({
    pitchdeckMetadata: Object
})
const emit = defineEmits(['questionAnswered'])

const { user } = getUser()
const { addDoc, error } = useCollection('messages')

// refs
const question = ref("");
const responsePending = ref(false);
const response = ref();
let messages


// Websocket part 

const connection_ready = ref(false);
const connection_error = ref(false);
const websocket = ref(null);


const init_chat = (pitchdeckId) => {
    //connect to socket url
    const websocket_url = `${process.env.VUE_APP_VC_SCOUT_BE_API_URL}/ws/${pitchdeckId}`;
    websocket.value = new WebSocket(websocket_url);

    websocket.value.onopen = onSocketOpen;
    websocket.value.onmessage = onSocketMessage;
    websocket.value.onerror = onSocketError;

};


const onSocketOpen = (evt) => {
    connection_ready.value = true;
    console.log("ws connection ready");


};

const onSocketMessage = (evt) => {
    const received = JSON.parse(evt.data);
    console.log("ws received this message");
    storeResponse(received);
    console.log(received);
};

const onSocketError = (evt) => {
    console.log("ws connection error");
    connection_error.value = true;
    storeResponse({ "answer": "Oops! Looks like my connection got tangled in the internet cables again. Let's try untangling them together, shall we? How about we hit the refresh button and get back on track? " })
    isBotLoading.toFalse()


};


const handleSubmit = async () => {


    try {
        isBotLoading.toTrue()
        let userChat = {
            name: user.value.displayName,
            userId: user.value.uid,
            pitchdeckId: props.pitchdeckMetadata.id,
            message: question.value,
            createdAt: timestamp()
        }

        await addDoc(userChat)
        const requestBody = {
            name: props.pitchdeckMetadata.companyName,
            dir_path: props.pitchdeckMetadata.dirPath,
            user_id: props.pitchdeckMetadata.userId,
            company_description: props.pitchdeckMetadata.companyDescription,
            company_website: props.pitchdeckMetadata.companyWebsite,
            company_crunchbase_links: props.pitchdeckMetadata.companyCrunchbaseLinks,
            company_linkedin_links: props.pitchdeckMetadata.companyLinkedinLinks,
            question: question.value,
        };
        question.value = ""
        responsePending.value = true;
        response.value = websocket.value.send(JSON.stringify(requestBody));




    } catch (error) {
        console.log(error);
        storeResponse({ "answer": "Can you try asking me one more time?" })
        isBotLoading.toFalse()
    }
}

function storeResponse(response) {
    let answerChat = {
        name: "bot",
        userId: user.value.uid,
        pitchdeckId: props.pitchdeckMetadata.id,
        message: response,
        createdAt: timestamp()
    }
    addDoc(answerChat)
    responsePending.value = false;
    emit("questionAnswered", {
        question: question.value,
        response: response,
    });
}

onMounted(() => {
    init_chat(props.pitchdeckMetadata.id);
    //warm up request to load parser. This is not stored in db, it serves the puepose of warming up the websocket
});




</script>


<style lang="scss" scoped></style>