import { ref } from 'vue'
import { projectAuth } from '../firebase/config'
import useCollection from './useCollection'
import User from '../model/User'
import { projectFunctions } from '../firebase/config'


const error = ref(null)
const isPending = ref(false)


const signup = async (email, password, displayName) => {
  error.value = null
  isPending.value = true

  try {
    const newUserRes = await projectAuth.createUserWithEmailAndPassword(email, password)
    if (!newUserRes) {
      throw new Error('Could not complete signup')
    }
    const getImageProfileUrl = projectFunctions.httpsCallable("getImageProfileUrl");
    var resp = await getImageProfileUrl()
    newUserRes.user.updateProfile({ displayName: displayName, photoURL: resp.data.result })
    _addUserToCollection(displayName, email, newUserRes.user.uid)

    error.value = null
    isPending.value = false

    return newUserRes
  }
  catch (err) {
    console.log(err.message)
    error.value = err.message
    isPending.value = false
  }
}

const useSignup = () => {
  return { error, signup, isPending }
}

export default useSignup

async function _addUserToCollection(displayName, email, uid) {
  const { addDocWithCustomId } = useCollection('users')
  let user = new User(displayName, email, uid, 20, false)
  try {
    await addDocWithCustomId({
      ...user
    }, uid)
  } catch (error) {
    console.log(error);
  }

}

