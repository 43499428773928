<template>
    <header>
        <LandingPageHeader />
        <div class="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 mt-16">
            
            <div class="sm:mx-auto sm:w-full sm:max-w-sm">
                <img class="mx-auto h-10 w-auto" src="@/assets/artificial-intelligence.png"
                    alt="Your Company" />
                <h2 class="mt-10 text-center text-2xl font-base leading-9 tracking-tight text-gray-900">Create an account
                </h2>
            </div>
            <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                <form class="space-y-6"  @submit.prevent="handleSubmit">
                    <div>
                        <label for="username" class="block text-sm font-medium leading-6 text-gray-900">Username</label>
                        <div class="mt-2">
                            <input v-model="displayName" id="username" name="username" type="text" required=""
                                class="block w-full rounded-md border-0 bg-white/5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6" />
                        </div>
                    </div>
                    <div>
                        <label for="email" class="block text-sm font-medium leading-6 text-gray-900">Email address</label>
                        <div class="mt-2">
                            <input v-model="email" id="email" name="email" type="email" autocomplete="email" required=""
                                class="block w-full rounded-md border-0 bg-white/5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6" />
                        </div>
                    </div>
                    <div>
                        <div class="flex items-center justify-between">
                            <label for="password" class="block text-sm font-medium leading-6 text-gray-900">Password</label>
                            <!-- <div class="text-sm">
                    <a href="#" class="font-semibold text-indigo-400 hover:text-indigo-300">Forgot password?</a>
                  </div> -->
                        </div>
                        <div class="mt-2">
                            <input v-model="password" id="password" name="password" type="password" autocomplete="current-password" required=""
                                class="block w-full rounded-md border-0 bg-white/5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6" />
                        </div>
                    </div>
                    <div v-if="error" class="text-red-500 text-sm">
                        {{ error }}
                    </div>
                    <div>
                        <button type="submit"
                            class="flex w-full justify-center rounded-md bg-indigo-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">Sign
                            up</button>
                    </div>
                </form>

            </div>
        </div>
    </header>
</template>
<script setup>  
import LandingPageHeader from '@/components/landing-page/LandingPageHeader.vue'
import useSignup from '@/composables/useSignup'
import { ref } from 'vue'
import { useRouter } from 'vue-router'
const { error, signup, isPending } = useSignup()
    const router = useRouter()

    const email = ref('')
    const password = ref('')
    const displayName = ref('')

    const handleSubmit = async () => {
      const res = await signup(email.value, password.value, displayName.value)
      if (!error.value) {
        router.push({ name: 'Home' })
      }
    }

</script>