import firebase from 'firebase/app';
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/functions'


const firebaseConfigProd = {
  apiKey: "AIzaSyAs878Tk126CXEEwczuOgAgxvlsbjs5CP4",
  authDomain: "vc-scout-prod.firebaseapp.com",
  projectId: "vc-scout-prod",
  storageBucket: "vc-scout-prod.appspot.com",
  messagingSenderId: "751341923991",
  appId: "1:751341923991:web:aea5de908fb60e1f3203d2",
  measurementId: "G-5CED859L9J"
};


// Firebase config starts here. Paste the new config here
const firebaseConfig = {
  apiKey: "AIzaSyCsLYOAWPEoLsWcd_IITsyP8VE155l5awQ",
  authDomain: "vc-scout.firebaseapp.com",
  projectId: "vc-scout",
  storageBucket: "vc-scout.appspot.com",
  messagingSenderId: "786307662294",
  appId: "1:786307662294:web:cfd0c915ba08b850646477",
  measurementId: "G-LK3HP17Q73"
};


if (process.env.VUE_APP_ENV == 'prod') {
  firebase.initializeApp(firebaseConfigProd)
}
else {
  firebase.initializeApp(firebaseConfig)
}

// init firebase
const app = firebase.app();


// init services
const projectFirestore = firebase.firestore()
const projectAuth = firebase.auth()
const projectStorage = firebase.storage()
var projectFunctions = app.functions("europe-west1")
if (process.env.NODE_ENV == 'development') {
  projectFunctions.useEmulator("localhost", 5001);
  projectFirestore.useEmulator("localhost", 5002)
  projectStorage.useEmulator("localhost", 9199)
  projectAuth.useEmulator("http://localhost:9099")
} else {
  projectFirestore.enablePersistence({ synchronizeTabs: true }).catch((err) => {
    if (err.code == 'failed-precondition') {
      // Multiple tabs open, persistence can only be enabled
      // in one tab at a a time.
      // ...
    } else if (err.code == 'unimplemented') {
      // The current browser does not support all of the
      // features required to enable persistence
      // ...
    }
  });
}
// timestamp
const timestamp = firebase.firestore.FieldValue.serverTimestamp

export { projectFirestore, projectAuth, projectStorage, projectFunctions, timestamp }