<template>
<div
    class="
      flex flex-row flex-colitems-center
      space-x-4
      cursor-pointer
      hover:bg-gray-50

      grow
    "
    v-on:click="$emit('companySelect',{
        companyName: crunchbaseResult.identifier.value,
        companyDescription: crunchbaseResult.short_description,
        companyImage: imageLink,
        isSelected: true
      });"
  >
    <div>
      <img class="w-16 h-16 rounded-sm" v-bind:src="imageLink" alt="" />
    </div>
    <div class="flex-1 min-w-0">
      <p class=" text-gray-900 truncate text-sm font-semibold">
        {{ crunchbaseResult.identifier.value }}
      </p>
      <p class="text-sm text-gray-500 truncate dark:text-gray-400 font-light">
        {{ crunchbaseResult.short_description }}
      </p>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { comment } from 'postcss';

export default {
    
  props: ["crunchbaseResult"],

  setup(props) {
    const imageLink = computed(()=> `https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/${props.crunchbaseResult.identifier.image_id}`) 

        return { imageLink };
  },
};
</script>
<style>
</style>