<template>
  <div class=" flex flex-col justify-between px-4 py-4 sm:px-6 sm:py-6 lg:px-8  ">
    <!-- <h1 class="font-light text-zinc-800 sm:text-6xl">
      VC-scout
      <span
        class="text-transparent bg-gradient-to-r from-blue-500 via-purple-500 to-purple-600 bg-clip-text text-6xl font-normal tracking-wider "
      >
        Analyze.
      </span>
    </h1> -->

    <!-- <div class="text-zinc-800 mt-10">
      <UploadPdf />
    </div> -->
    <h1 class="text-3xl font-bold leading-7 text-gray-900">Create Report</h1>




    <form class="flex  flex-col space-y-5 max-w-2xl text-wrap" @submit.prevent="handleSubmit">
      <p class="text-gray-900 text-sm font-normal antialiased block my-4">
        To get started, choose the company you would like to analyze and upload the company's pitchdeck, financial
        statements, regulatory findings... We will analyze the provided documents
        and deliver you a detailed investment report.
      </p>
      <div
        class="flex rounded-md ring-1 ring-inset ring-gray-900/10 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
        <input class="max-w-full	flex-1 border-0 bg-transparent py-1.5 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6"
          type="text" required placeholder="Company name" v-model="companyName" />
      </div>
      <textarea
        class="block w-full rounded-md border-0  py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-900/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
        type="text" required placeholder="Company description" v-model="companyDescription" />

      <div v-if="!isSelected">
        <div v-for="(result, index) in searchCompanyNamesResults">
          <AutoCompleteFieldVue :crunchbaseResult="result" v-on:companySelect="onCompanySelect" />
        </div>
      </div>
      <input class="text-sm text-gray-400" id="file-upload" type="file" multiple="multiple" name="filefield"
        @change="handlChange" />

      <!-- <p class="text-gray-900">
          {{ searchCompanyNamesResults }}
        </p> -->
      <div v-if="fileErrors.length !== 0" class="error">{{ fileErrors }}</div>

      <!-- custom questions section-->
      <div class="pt-4">
        <div class="pb-4 flex flex-row gap-x-4">
          <Switch v-model="customQuestionsEnabled"
            :class="[customQuestionsEnabled ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2']">
            <span class="sr-only">Use setting</span>
            <span aria-hidden="true"
              :class="[customQuestionsEnabled ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']" />
          </Switch>
          <p class="text-sm text-gray-500">
            Use custom questions
          </p>
        </div>

        <div v-show="customQuestionsEnabled" class="flex  flex-col space-y-5 max-w-2xl text-wrap">
          <p class="text-gray-900 text-sm font-normal antialiased block my-4">
            Instead of using the default cortaix investemnt report template (curated by our team of experts), you can
            instead use your own custom questions.
          </p>
          <form @submit.prevent="handleAddCustomQuestion" class="flex flex-row gap-x-4 ">
            <div class="m-auto">
              <button
                class="hover:text-green-500 hover:cursor-pointer h-5 w-5 flex-none text-gray-400  disabled:text-gray-400 disabled:cursor-not-allowed"
                aria-hidden="true" :disabled="customQuestion === ''" @click="handleAddCustomQuestion">
                <PlusCircleIcon />
              </button>
            </div>
            <textarea
            @keydown.enter.prevent="handleAddCustomQuestion"
              class=" basis-7/8 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-900/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
              type="text" required placeholder="Write your question here.." v-model="customQuestion" />

          </form>
          <div>
            <div v-for="(value, key) in customQuestions" class=" flex flex-col gap-y-8">
              <div class="flex flex-row gap-x-4 py-2">
                <div class="m-auto">
                  <MinusCircleIcon class="hover:text-red-500 hover:cursor-pointer h-5 w-5 flex-none text-gray-400"
                    aria-hidden="true" @click="handleRemoveCustomQuestion(key)" />
                </div>
                <div class="basis-7/8 block w-full ">
                  <span
                    class="inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-sm font-medium text-gray-900 ring-1 ring-inset ring-gray-200">
                    {{ value }}</span>

                </div>


              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="!isPending" class="w-96">
        <button>
          <Button text="Create" :loading="isPending" :disabled=" noQuestionsAdded" @click="handleSubmit" />
        </button>
      </div>
      <div v-else class="w-96">
        <button><Button text="Loading" :loading="isPending" /></button>
      </div>
    </form>
  </div>
</template>

<script setup>
import useStorage from "@/composables/useStorage";
import useCollection from "@/composables/useCollection";
import { timestamp } from "@/firebase/config";
import { useRouter } from "vue-router";
import getUser from "@/composables/getUser";
import Button from "@/components/buttons/Button.vue";
import { computed, ref, watch } from "vue";
import { projectFunctions } from "@/firebase/config";
import AutoCompleteFieldVue from "@/components/forms/AutoCompleteField.vue";
import { PlusCircleIcon, MinusCircleIcon } from '@heroicons/vue/24/outline'
import { Switch } from '@headlessui/vue'


const router = useRouter();
var filePaths = []
var urlPaths = []
const { url, dirPath, uploadFile } = useStorage();
const { error, addDoc } = useCollection("pitchdecks");
const { user } = getUser();
const isPending = ref(false);


const customQuestionsEnabled = ref(false)
const customQuestion = ref("")
const customQuestions = ref({})
const noQuestionsAdded = computed(() => {
  return Object.keys(customQuestions.value).length === 0 && customQuestionsEnabled.value;
});
const companyName = ref("");
const companyDescription = ref("");
const companyThumbnail = ref("");
const isSelected = ref(false);
const companyWebsite = ref("");
const companyCrunchbaseLinks = ref([]);
const companyLinkedinLinks = ref([]);

const filesToUpload = ref([]);
const fileErrors = ref([]);
const fileError = ref(null);
const getAutocompleteFromCrunchbase = projectFunctions.httpsCallable("getAutocompleteFromCrunchbase");
const postReportLangchainVer2 = projectFunctions.httpsCallable("postReportLangchainVer2");

const searchCompanyNamesResults = ref(null);
const searchResults = ref(null);
// allowed file types
const types = ["application/pdf"];

function onCompanySelect(company) {
  companyName.value = company.companyName;
  companyDescription.value = company.companyDescription;
  companyThumbnail.value = company.companyImage;
  isSelected.value = company.isSelected;
}

watch(companyName, async (companyName) => {
  if (companyName == "") {
    searchCompanyNamesResults.value = [];
    isSelected.value = false;
  }
  await getAutocompleteFromCrunchbase({ searchQuery: companyName })
    .then((result) => {
      searchResults.value = result.data.entities;
      console.log("SEARCH_RESULTS ", searchResults);
    })
    .catch((error) => {
      console.log("SEARCH_RESULTS ", error);
    });
  searchCompanyNamesResults.value = searchResults.value;
});
const handleSubmit = async () => {
  isPending.value = true;
  if (filesToUpload.value.length !== 0) {
    for (let file of filesToUpload.value) {
      var [filePath, urlPath] = await uploadFile(file, "pitchdecks", companyName.value);
      filePaths.push(filePath);
      urlPaths.push(urlPath);
    }

      // add a record to firestore collection
      const res = await addDoc({
      companyName: companyName.value,
      companyDescription: companyDescription.value,
      companyThumbnail: companyThumbnail.value,
      companyWebsite: companyWebsite.value,
      companyCrunchbaseLinks: companyCrunchbaseLinks.value,
      companyLinkedinLinks: companyLinkedinLinks.value,
      userId: user.value.uid,
      userName: user.value.displayName,
      fileUrls: urlPaths,
      filePaths: filePaths,
      dirPath: dirPath.value,
      createdAt: timestamp(),
      tags: [],
      isReportReady: "0",
    });


    // Send request to vc-backend to generate report
    if (customQuestionsEnabled.value) {
      var customQuestionTosend = customQuestions.value;
    }
    else {
      var customQuestionTosend = {};
    }
    const vcBackendRequestBody = {
      name: companyName.value,
      dir_path: dirPath.value,
      user_id: user.value.uid,
      pitchdeck_id: res.id,
      company_website: companyWebsite.value,
      company_description: companyDescription.value,
      company_crunchbase_links: companyCrunchbaseLinks.value,
      company_linkedin_links: companyLinkedinLinks.value,
      custom_questions: customQuestionTosend,
    };
    await postReportLangchainVer2(vcBackendRequestBody)
      .then((result) => {
        console.log("VC BACKEND ", result);
      })
      .catch((error) => {
        console.log("VC BACKEND ERROR", error);
      });
    if (!error.value) {
      router.push({
        name: "AnalysisDetails",
        params: {
          id: res.id,
          collection_name: "reports_langchain_v2"
        },
      });
    }
  }
  isPending.value = false;
};

const handleAddCustomQuestion = () => {
  customQuestions.value[Object.keys(customQuestions.value).length + 1] = customQuestion.value;
  customQuestion.value = "";
};
const handleRemoveCustomQuestion = (index) => {
  delete customQuestions.value[index];
};

const handlChange = (e) => {
  let fil = null;
  const selected = e.target.files;
  for (fil of selected) {
    if (fil && types.includes(fil.type)) {
      console.log(fil.type);
      filesToUpload.value.push(fil);
    } else {
      fileErrors.value.push("Please select only pdf files");
      filesToUpload.value = [];
    }
  }


  //if (selected && types.includes(selected.type)) {
  //   file.value = selected;
  //   fileError.value = null;
  // } else {
  //   fileError.value = "Please select a pdf file";
  //   file.value = null;
  // }
};
</script>

<style></style>
UploadPdf