<template>
  <header class="flex items-center justify-between border-b border-gray-50 px-4 py-4 sm:px-6 sm:py-6 lg:px-8">
    <h1 class="text-3xl font-bold leading-7 text-gray-900">Reports</h1>

    <!-- Sort dropdown -->
    <Menu as="div" class="relative">
      <MenuButton class="flex items-center gap-x-1 text-sm font-medium leading-6 text-white">
        Sort by
        <ChevronUpDownIcon class="h-5 w-5 text-gray-500" aria-hidden="true" />
      </MenuButton>
      <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75"
        leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
        <MenuItems
          class="absolute right-0 z-10 mt-2.5 w-40 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
          <MenuItem v-slot="{ active }" @click="setActiveMenuItem('Name')">
          <a href="#" :class="[active ? 'bg-gray-50' : '', 'block px-3 py-1 text-sm leading-6 text-gray-900']">Name</a>
          </MenuItem>
          <MenuItem v-slot="{ active }" @click="setActiveMenuItem('Date')">
          <a href="#" :class="[active ? 'bg-gray-50' : '', 'block px-3 py-1 text-sm leading-6 text-gray-900']">Date
            updated</a>
          </MenuItem>
          <MenuItem v-slot="{ active }" @click="setActiveMenuItem('State')">
          <a href="#" :class="[active ? 'bg-gray-50' : '', 'block px-3 py-1 text-sm leading-6 text-gray-900']">State
            updated</a>
          </MenuItem>
        </MenuItems>
      </transition>
    </Menu>
  </header>
  <!-- New project prompt -->
  <div v-show="isNullOrUndefinedOrEmptyArray(pitchdecks)" class="text-center mt-24">
    <router-link :to="{ name: 'Analyze' }">
      <svg class="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor"
        aria-hidden="true">
        <path vector-effect="non-scaling-stroke" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
          d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z" />
      </svg>
      <p class="mt-1 text-sm text-gray-500">Get started by creating a new report.</p>
      <div class="mt-6">
        <button type="button"
          class="inline-flex items-center border-2 border-gray-400 rounded-md bg-transparent px-3 py-2 text-sm font-normal text-gray-400 shadow-sm hover:text-gray-900 hover:border-gray-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
          <PlusIcon class="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
          New Report
        </button>
      </div>
    </router-link>
  </div>
  <!-- Companies list -->
  <ul role="list" class="divide-y divide-gray-50">
    <li v-for="pitchdeck in sortedList" :key="pitchdeck.id"
      class="relative flex items-center space-x-4 px-4 py-4 sm:px-6 lg:px-8">
      <div class="min-w-0 flex-auto">
        <div class="flex items-center gap-x-3">
          <div class="hidden md:block">
            <div :class="[statuses[pitchdeck.isReportReady], 'flex-none rounded-full p-1']">
              <div
                :class="[isFinishedProcessing(pitchdeck.isReportReady) ? ' h-2 w-2 rounded-full bg-current' : 'pulsating-circle h-2 w-2 rounded-full bg-current']" />
            </div>
          </div>
          <h2 class="min-w-0 text-sm font-semibold leading-6 text-gray-900">
            <router-link :to="{ name: 'AnalysisDetails', params: { id: pitchdeck.id } }" class="flex gap-x-2">
              <span class="">{{ pitchdeck.companyName }}</span>
              <span class="text-gray-400">/</span>
              <span class=" font-normal">{{ pitchdeck.companyDescription }}</span>
            </router-link>
          </h2>
        </div>
        <div class="mt-3 flex items-center gap-x-2.5 text-xs leading-5 text-gray-400">
          <p class="truncate">{{ pitchdeck.createdAt.toDate().toDateString() }}</p>
          <svg viewBox="0 0 2 2" class="h-0.5 w-0.5 flex-none fill-gray-300">
            <circle cx="1" cy="1" r="1" />
          </svg>
          <p class="whitespace-nowrap">{{ pitchdeck.userName }}</p>
        </div>
      </div>
      <div
        :class="[statusIcons[pitchdeck.isReportReady], 'rounded-full flex-none py-1 px-2 text-xs font-medium ring-1 ring-inset']">
        {{ readiness[pitchdeck.isReportReady] }}</div>
      <ChevronRightIcon class="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
      <MinusCircleIcon class="hover:text-red-500 hover:cursor-pointer h-5 w-5 flex-none text-gray-400" aria-hidden="true"
        @click="handleDelete(pitchdeck)" />

    </li>
  </ul>
</template>

<script setup>
import { ref } from "vue";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from '@headlessui/vue'
import { ChevronRightIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid'
import { MinusCircleIcon } from '@heroicons/vue/24/outline'
import { onMounted } from "vue";
import { projectStorage, projectFirestore } from "@/firebase/config";
import getCollection from "@/composables/getCollection";
import getUser from "@/composables/getUser";
import { computed } from "vue";
import { PlusIcon } from '@heroicons/vue/20/solid'


const statuses = {
  "-1": 'text-red-400 bg-red-400/10',
  "0": 'text-gray-500 bg-gray-400/10',
  "1": 'text-green-400 bg-green-400/10',
  // error: 'text-rose-400 bg-rose-400/10',
}
const statusIcons = {
  "-1": 'text-red-400 bg-red-400/10 ring-red-400/20',
  "0": 'text-gray-400 bg-gray-400/10 ring-gray-400/20',
  "1": 'text-green-400 bg-green-400/10 ring-green-400/30',
}

const readiness = {
  "-1": 'Error',
  "0": 'Processing...',
  "1": 'Ready',

}

const { user } = getUser();
const { error: fetchError, documents: pitchdecks } = getCollection(
  "pitchdecks",
  ["userId", "==", user.value.uid]
);

const activeMenuItem = ref("Date");

const setActiveMenuItem = (menuItem) => {
  activeMenuItem.value = menuItem;
};



function isFinishedProcessing(isReportReadyVar) {
  return (isReportReadyVar === "1" || isReportReadyVar=== "-1");
}
const sortedList = computed(() => {
  if (pitchdecks.value != null) {
    const listCopy = [...pitchdecks.value]; // Create a copy of the original list
    if (activeMenuItem.value === 'Name') {
      return listCopy.sort((a, b) => a.companyName.localeCompare(b.companyName)); // Sort by name
    } else if (activeMenuItem.value === 'Date') {
      return listCopy.sort((a, b) => b.createdAt - a.createdAt); // Sort by date
    } else if (activeMenuItem.value === 'State') {
      return listCopy.sort((a, b) => Number(b.isReportReady) - Number(a.isReportReady)); // Sort by environment
    } else {
      return listCopy; // Return the original list if no active menu item is selected
    }
  }

});

async function handleDelete(pitchdeck) {

  try {
    await projectFirestore.collection("pitchdecks").doc(pitchdeck.id).delete();
  } catch (error) {
    console.log(error.message)
  }
  try {
    await projectFirestore.collection("reports_langchain_v2").doc(pitchdeck.companyName).delete();
  } catch (error) {
    console.log(error.message)
  }
  try {
    await projectFirestore.collection("reports_langchain_v2_delta_format").doc(pitchdeck.companyName).delete();
  } catch (error) {
    console.log(error.message)
  }
  try {
    for (let filePath of pitchdeck.filePaths) {
      console.log(filePath)
      await projectStorage.ref(filePath).delete();
    }
  } catch (error) {
    console.log(error.message)
  }
}


function isNullOrUndefinedOrEmptyArray(variable) {
  return variable === null || variable === undefined || (Array.isArray(variable) && variable.length === 0);
}

</script>

<style lang="scss" scoped>
/* Keyframe animation for pulsating effect */
@keyframes pulse {
  0% {
    transform: scale(0.8);
    opacity: 0.7;
  }

  50% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(0.8);
    opacity: 0.7;
  }
}

/* Apply animation to the pulsating circle */
.pulsating-circle {
  animation: pulse 2s ease-in-out infinite;
  /* You can adjust animation duration and timing function */
}</style>