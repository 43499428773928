<template>

    <div ref="messagesRef" class="max-h-[500px] overflow-auto py-4 ">
        <div v-for="message in formattedMessages" :key="message.id">
            <div v-if="message.name == 'bot'">
                <BotBubble :message="message" />
            </div>
            <div v-else>
                <UserBubble :message="message" :userDisplayName="user.displayName" :userPhotoUrl="user.photoURL" />
            </div>
        </div>
        <div>
            <BotBubbleLoading v-if="isBotLoading.value"/>
        </div>
    </div>
    <ChatFormWs :pitchdeckMetadata="pitchdeckMetadata" @questionAnswered="handleQuestionAnswered" />
</template>

<script setup>
import getCollection from '@/composables/getCollection';
import ChatFormWs from './ChatFormWs.vue';
import BotBubble from './BotBubble.vue';
import UserBubble from './UserBubble.vue';
import BotBubbleLoading from './BotBubbleLoading.vue';
import { onUpdated, ref } from 'vue';
import { formatDistanceToNow } from 'date-fns'
import {computed } from 'vue'
import getUser from '@/composables/getUser'
import {isBotLoading} from './loader.js'
const props = defineProps({
    pitchdeckMetadata: Object
})


const {user} = getUser()
const { documents: messages, error: fetchError } = getCollection('messages',
    ["pitchdeckId", "==", props.pitchdeckMetadata.id],
    ["userId", "==", props.pitchdeckMetadata.userId])

const messagesRef = ref(null)
onUpdated(() => {
    messagesRef.value.scrollTop = messagesRef.value.scrollHeight
})
const formattedMessages = computed(() => {
      if (messages.value) {
        return messages.value.map(doc => {
          let time = formatDistanceToNow(doc.createdAt.toDate())
          return { ...doc, createdAt: time }
        })
      }
    })

const handleQuestionAnswered = (data) => {
    isBotLoading.toFalse()
    console.log("BOT",isBotLoading)
}


</script>

<style lang="scss" scoped></style>