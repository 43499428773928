import { reactive } from 'vue'

export const isBotLoading = reactive({
    value: false,
    toTrue(){
        this.value = true
    },
    toFalse(){
        this.value = false
    }
})