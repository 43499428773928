<template>
<div class="flex-row gap-2 flex">
    <div class="flex-col gap-1 mt-10 flex">
        <div class="h-8 w-72 rounded-md animate-pulse bg-neutral-300 dark:bg-neutral-700"></div>
    </div>
</div>
<div class="flex-row h-12 gap-0 flex">
    <div class="h-5 w-96 rounded-md mt-8 animate-pulse bg-neutral-300 dark:bg-neutral-700"></div>
</div>
<div class="flex-col gap-3 mt-8 flex">
    <div class="h-3 w-96 rounded-md animate-pulse bg-neutral-300 dark:bg-neutral-700"></div>
    <div class="h-3 w-80 rounded-md animate-pulse bg-neutral-300 dark:bg-neutral-700"></div>
    <div class="h-3 w-96 rounded-md animate-pulse bg-neutral-300 dark:bg-neutral-700"></div>
    <div class="h-3 w-64 rounded-md animate-pulse bg-neutral-300 dark:bg-neutral-700"></div>
</div>
<div class="flex-row h-12 gap-0 flex">
    <div class="h-5 w-96 rounded-md mt-8 animate-pulse bg-neutral-300 dark:bg-neutral-700"></div>
</div>
<div class="flex-col gap-3 mt-8 flex">
    <div class="h-3 w-96 rounded-md animate-pulse bg-neutral-300 dark:bg-neutral-700"></div>
    <div class="h-3 w-80 rounded-md animate-pulse bg-neutral-300 dark:bg-neutral-700"></div>
    <div class="h-3 w-96 rounded-md animate-pulse bg-neutral-300 dark:bg-neutral-700"></div>
    <div class="h-3 w-64 rounded-md animate-pulse bg-neutral-300 dark:bg-neutral-700"></div>
</div>


</template>

<script setup>

</script>

<style lang="scss" scoped>

</style>