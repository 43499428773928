<template>
  <div class="text-gray-900 font-light" v-if="isReportReady==='1'">
    <section class="pt-6">
      <div v-if="isEditable" class="py-4 mt-2">
        <QuillEditor theme="snow" v-model:content="deltaObject" :contentType="delta" />
      </div>
      <div v-else class="py-4 mt-4">
        
        <SimpleOutput :finalReport="finalReport" :pitchdeckMetadata="pitchdeckMetadata" :isSimpleQAReport="isSimpleQAReport" />
      </div>
<!--       <div class="text-right">
        <div v-if="isEditable">
          <div class="flex justify-end">
            <a @click="handleSave"
              class="cursor-pointer w-32 mb-4 flex justify-center items-center gap-x-1 rounded-md bg-indigo-600 px-3 py-2 text-sm font-normal text-gray-900 shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
              <ArrowDownTrayIcon class="-ml-1.5 h-4 w-4" aria-hidden="true" />
              Save
          </a>
          </div>
        </div>
        <div v-else>
          <div class="flex justify-end">
            <a @click="handleEdit"
              class="cursor-pointer w-32 mb-4 flex justify-center items-center gap-x-1 rounded-md bg-indigo-600 px-3 py-2 text-sm font-normal text-gray-900 shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
              <PencilIcon class="-ml-1.5 h-4 w-4" aria-hidden="true" />
              Edit
          </a>
          </div>
        </div>
      </div> -->
    </section>
    <hr class="h-px bg-gray-200 border-0 dark:bg-gray-300">


    <div class=" bottom-0">
      <ChatWindow :pitchdeckMetadata="pitchdeckMetadata" />
    </div>

  </div>

  <div v-else-if="isReportReady==='0'" class="text-gray-900 antialiased mt-4 font-light px-5">
    <LoadingReport />
  </div>
  <div v-else>
    <FailedReport />
  </div>
  <div> 

  </div>
</template>

<script setup>
import getDocument from "@/composables/getDocument";
import { ref, watch, onMounted } from "vue";
import useDocument from "@/composables/useDocument";
import { projectFirestore } from "@/firebase/config";
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import { transformJson } from "@/composables/helpers.js";
import Delta from "quill-delta";
import SimpleOutput from "@/components/report/SimpleOutput.vue";
import ChatWindow from "@/components/chat/ChatWindow.vue";
import LoadingReport from "@/components/report/LoadingReport.vue";
import FailedReport from "@/components/report/FailedReport.vue";
import { PencilIcon, ArrowDownTrayIcon} from '@heroicons/vue/24/outline'

const props = defineProps({
  id: String,
  collection_name: String,
})


const { document: pitchdeckMetadata, error: fetchError } = getDocument(
  "pitchdecks",
  props.id
);


const companyThumbnail = ref("");
const companyName = ref(null);
const companyWebsite = ref("");
const crunchbaseWebsite = ref(undefined)
const linkedInWebsite = ref(undefined)
const reportIsPending = ref(true);
const finalReport = ref("");
var isSimpleQAReport = ref(false)
const isReportReady = ref("0")

const questionAnswerList = ref([
  { question: "What is your name?", answer: "My name is..." },
]);
const deltaObject = ref(new Delta())
const deltaObjectExists = ref(false)
const isEditable = ref(false);

watch(pitchdeckMetadata, async () => {
  try {
    isReportReady.value = pitchdeckMetadata.value.isReportReady
    companyWebsite.value = pitchdeckMetadata.value.companyWebsite;
    companyThumbnail.value = pitchdeckMetadata.value.companyThumbnail;
    companyName.value = pitchdeckMetadata.value.companyName;
    [crunchbaseWebsite.value] = pitchdeckMetadata.value.companyCrunchbaseLinks || [];
    [linkedInWebsite.value] = pitchdeckMetadata.value.companyLinkedinLinks || [];
    // console.log(linkedInWebsite.value)
    let deltaObjectContent = await projectFirestore.collection("reports_langchain_v2_delta_format").doc(pitchdeckMetadata.value.id).get()
    // console.log(deltaObjectContent)
    if (deltaObjectContent.exists === true) {
      deltaObjectExists.value = true;
      deltaObject.value = new Delta(deltaObjectContent.data())
      reportIsPending.value = false;
      isEditable.value = true;
    }
    else {
      let getFinalReportData = await projectFirestore
        .collection("reports_langchain_v2")
        .doc(pitchdeckMetadata.value.id)
        .get();
      const { createdAt, userId, ...buff } = getFinalReportData.data();
      finalReport.value = buff;
      // console.log("Final report from DB")
      // console.log(finalReport.value)
      verifyIfSimpleQAReport(finalReport)
      deltaObject.value = transformJson(finalReport.value)
      reportIsPending.value = false;
    }

  } catch (err) {
    console.log(err);
  }
});

function verifyIfSimpleQAReport(finalReport) {
  if (finalReport.value[1] === undefined) {
    isSimpleQAReport.value = false;
  }
  else {
    isSimpleQAReport.value = true;
  }
}

function handleSave() {
  try {
    if (deltaObjectExists.value === false) {
      projectFirestore.collection("reports_langchain_v2_delta_format").doc(pitchdeckMetadata.value.id).set(JSON.parse(JSON.stringify(deltaObject.value)))
    }
    else {
      const { updateDoc } = useDocument(
        "reports_langchain_v2_delta_format",
        pitchdeckMetadata.value.id
      );
      updateDoc(JSON.parse(JSON.stringify(deltaObject.value)));
    }

  } catch (err) {

    console.log(err)
  }
}

function handleEdit() {
  isEditable.value = true;
}

function onQuestionAnswered(obj) {
  questionAnswerList.value.push(obj);
}

const tabs = [
  { name: 'Report', href: '#', current: true },
  // { name: 'Stat', href: '#', current: false },
]



</script>

<style lang="scss" scoped></style>