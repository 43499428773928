<template>
    <!-- <Navbar /> -->
    <div class="content">
      <router-view />
    </div>
</template>


<script>
import { useRoute } from 'vue-router'
import { computed } from 'vue'
export default {
  components: {  },
  setup() {
    const route = useRoute();
    const isLandingPage = computed(() => (route.path === '/' || route.path === '/login' || route.path === '/signup'))
    return { isLandingPage }
  }
};



</script>

<style>
.content {
  margin: 0 auto;
  max-width: auto;
  padding: 0 0px;
}

.landingpage {
  background-color: #f6f9fc;
  height:100vh
}
</style>
