import { ref } from 'vue'
import { projectAuth, projectFirestore } from '../firebase/config'
import firebase from 'firebase/app';
import { _ } from 'core-js';
import useCollection from './useCollection'
import User from '../model/User'
import { projectFunctions } from '../firebase/config'

const error = ref(null)
const isPending = ref(false)

const login = async (email, password) => {
  error.value = null
  isPending.value = true

  try {
    const res = await projectAuth.signInWithEmailAndPassword(email, password)
    error.value = null
    isPending.value = false
    return res
  }
  catch (err) {
    console.log(err.message)
    error.value = 'Incorrect login credentials'
    isPending.value = false

  }
}

// redirect to google login

const singInWithGoogle = async () => {
  var provider = new firebase.auth.GoogleAuthProvider();
  provider.addScope('https://www.googleapis.com/auth/userinfo.profile').addScope('https://www.googleapis.com/auth/userinfo.email');
  projectAuth.useDeviceLanguage()
  projectAuth
    .signInWithRedirect(provider)
  // .then(async (result) => {
  //   /** @type {firebase.auth.OAuthCredential} */
  //   var credential = result.credential;
  //   var token = credential.accessToken;
  //   // The signed-in user info.
  //   var user = result.user;
  //   console.log("USER GOOGLE");
  //   console.log(user);
  //   _createNewUserIfnotExists(user.displayName, user.email, user.uid)
  // }).catch((error) => {
  //   // Handle Errors here.
  //   var errorCode = error.code;
  //   var errorMessage = error.message;
  //   // The email of the user's account used.
  //   var email = error.email;
  //   // The firebase.auth.AuthCredential type that was used.
  //   var credential = error.credential;
  //   // ...
  // });

}


const useLogin = () => {
  return { error, login, singInWithGoogle, isPending }
}


export default useLogin