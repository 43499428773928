<template>
    <div class="flex h-16 shrink-0 items-center">
        <p class="text-black hover:text-gray-900 font-normal text-xl antialiased tracking-widest">cortAIx</p>
    </div>
    <nav class="flex flex-1 flex-col">
        <router-link :to="{ name: 'Analyze' }"
            class="mb-4 mr-auto flex items-center gap-x-1 rounded-md bg-indigo-600 px-3 py-2 text-sm font-normal text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
            <PlusSmallIcon class="-ml-1.5 h-5 w-5" aria-hidden="true" />
            New Report
        </router-link>
        <ul role="list" class="flex flex-1 flex-col gap-y-7">
            <li>
                <ul role="list" class="-mx-2 space-y-1">

                    <li v-for="item in navigation" :key="item.name">
                        <router-link :to="{ name: item.linkName }"
                            class=" text-gray-900  hover:bg-gray-50 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-normal">
                            <component :is="item.icon" class="h-6 w-6 shrink-0" aria-hidden="true" />
                            {{ item.name }}
                        </router-link>
                    </li>
                </ul>
            </li>
            <!-- <li>
                <div class="text-xs font-semibold leading-6 text-gray-400">Your teams</div>
                <ul role="list" class="-mx-2 mt-2 space-y-1">
                    <li v-for="team in teams" :key="team.name">
                        <a :href="team.href"
                            :class="[team.current ? 'bg-gray-800 text-white' : 'text-gray-400 hover:text-white hover:bg-gray-800', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold']">
                            <span
                                class="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[0.625rem] font-medium text-gray-400 group-hover:text-white">{{
                                    team.initial }}</span>
                            <span class="truncate">{{ team.name }}</span>
                        </a>
                    </li>
                </ul>
            </li> -->
            <Menu as="div" class="relative -mx-6 mt-auto">
                <MenuButton class=" hover:bg-gray-50 w-full">
                    <a class="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-gray-900">

                        <img class="h-8 w-8 rounded-full bg-white" :src="user.photoURL"
                            alt="https://source.unsplash.com/random?abstract" />


                        <span class="sr-only">Your profile</span>
                        <span aria-hidden="true">{{ user.displayName }}</span>
                    </a>

                </MenuButton>
                <transition enter-active-class="transition ease-out duration-100"
                    enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100"
                    leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100"
                    leave-to-class="transform opacity-0 scale-95">

                    <MenuItems
                        class="absolute left-2 bottom-16 z-10 mt-2.5 w-40 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                        <MenuItem v-slot="{ active }" @click="handleLogout">
                        <div class="flex justify-between cursor-pointer"> <a
                                class="block px-3 py-1 text-sm leading-6 text-gray-900">Logout</a>
                            <ArrowRightOnRectangleIcon class="h-5 w-5 my-auto mr-2 text-gray-900" />
                        </div>
                        </MenuItem>

                    </MenuItems>

                </transition>
            </Menu>
        </ul>
    </nav>
</template>

<script setup>


import {
    ChartBarSquareIcon,
    Cog6ToothIcon,
    FolderIcon,
    GlobeAltIcon,
    ServerIcon,
    SignalIcon,
    PlusSmallIcon,
    ArrowRightOnRectangleIcon,
    CreditCardIcon
} from '@heroicons/vue/24/outline'

import {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
} from '@headlessui/vue'
import { useRouter } from "vue-router";
import useLogout from "@/composables/useLogout";
import getUser from "@/composables/getUser";
const { error, logout, isPending } = useLogout();
const router = useRouter();
const { user } = getUser();
const navigation = [
    { name: 'Reports', linkName: 'Home', icon: FolderIcon },
    { name: 'Settings', linkName: 'Payment', icon: Cog6ToothIcon },
    // { name: 'Settings', linkName: 'Payment', icon: Cog6ToothIcon},
]
const handleLogout = async () => {
    await logout();
    if (!error.value) {
        console.log("user is logged out");
    }
    router.push({ name: "Login" });
};
</script>

<style lang="scss" scoped>
.router-link-active {
    background-color: rgb(249,250,251);
    color: black;
    font-weight: 600;
}
</style>