import { projectStorage } from "@/firebase/config";
import { ref } from "vue";
import getUser from "./getUser";

const { user } = getUser()

const useStorage = () => {
    const error = ref(null)
    const url = ref(null)
    const filePath = ref(null)
    const dirPath = ref(null)

    const uploadFile = async (file,dir,subdir) => {
        dirPath.value = `${dir}/${user.value.uid}/${subdir}`
        filePath.value = `${dir}/${user.value.uid}/${subdir}/${file.name}`
        const storageRef = projectStorage.ref(filePath.value)
        try {
            const res = await storageRef.put(file)
            url.value = await res.ref.getDownloadURL()
            return [filePath.value, url.value]
        } catch (err) {
            console.log(err.message)
            error.value = err.message

        }
    }

    const deleteFile = async(path) => {
        const storageRef = projectStorage.ref(path)
        try {
            await storageRef.delete()
        } catch (err) {
            console.log(err.message);
            error.value = err.message
            
        }
    }

    return { url, filePath, dirPath, error, uploadFile, deleteFile }
}
export default useStorage