<template>
    <div class="flex justify-start mb-4">
        <div class="flex items-center justify-center h-8 w-8 rounded-full bg-zinc-800">
            <p class="text-center text-white m-0">C</p>
        </div>
        <div class="ml-2 py-3 px-4 bg-gray-600 rounded-br-3xl rounded-tr-3xl rounded-tl-xl text-white">
            <div class="messenger-typing">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
            </div>
        </div>
    </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>
.messenger-typing {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10px;
    margin: 5px;
}

.dot {
    width: 10px;
    height: 10px;
    background-color: black;
    /* Messenger blue color */
    border-radius: 50%;
    margin: 0 3px;
    opacity: 0;
    animation: dot-blink 1.2s infinite;
}

.dot:nth-child(2) {
    animation-delay: 0.2s;
}

.dot:nth-child(3) {
    animation-delay: 0.4s;
}

@keyframes dot-blink {

    0%,
    100% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}
</style>