<template>
    <div class=" mt-16 ">
        <img src="@/assets/sadpuppy.png" alt="" class="mx-auto">
        <div class="mx-auto w-96">
            Oops, looks like there's a hiccup in getting your report ready! Try generating a fresh one and get everything back on track! 🐶✨</div>

    </div>

</template>

<script setup>

</script>

<style lang="scss" scoped>

</style>