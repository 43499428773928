<template>
    <div v-if="!isSimpleQAReport">
        <div v-for="(value1, key1) in sortSections(finalReport)" :key="key1">
            <h1 class="text-3xl font-bold">{{ key1 }}</h1>
            <br>
            <div v-for="(value2, key2) in value1" :key="key2">
                <!-- <h2 class="text-lg font-medium"> {{ key2 }}</h2> -->
                <div class="mb-12">
                    <div v-for="(value3, key3) in sortQAS(value2)" class="">
                        <!-- <p>{{ key3 }}</p> -->
                        <div v-if="key3 === 'Question'" class=" text-gray-900 font-semibold text-xl pb-4">{{ value3 }}</div>
                        <div class="text-base font-normal pb-4 " v-else-if="key3 === 'Answer'">{{ value3 }}</div>
                        <div v-else>
                            <div v-if="Array.isArray(value3)">
                                <div v-for="source in value3" :key="source.key" class="italic text-red-500 pt-2">
                                    <div :v-if="pitchdeckMetadataReady">
                                        <a :href="addSourceUrl(source)" target="_blank"
                                            class="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">{{
                                                source
                                            }}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br>
            <br>
        </div>
    </div>
    <div v-else>
        <div v-for="(value2, key2) in finalReport" :key="key2">
            <!-- <h2 class="text-lg font-medium"> {{ key2 }}</h2> -->
            <div class="mb-12">
                <div v-for="(value3, key3) in sortQAS(value2)" class="">
                    <!-- <p>{{ key3 }}</p> -->
                    <div v-if="key3 === 'Question'" class=" text-gray-900 font-semibold text-xl pb-4">{{ value3 }}</div>
                    <div class="text-base font-normal pb-4 " v-else-if="key3 === 'Answer'">{{ value3 }}</div>
                    <div v-else>
                        <div v-if="Array.isArray(value3)">
                            <div v-for="source in value3" :key="source.key" class="italic text-red-500 pt-2">
                                <div :v-if="pitchdeckMetadataReady">
                                    <a :href="addSourceUrl(source)" target="_blank"
                                        class="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">{{
                                            source
                                        }}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { onMounted, ref, watch } from 'vue';

export default {

    props: ['finalReport', 'pitchdeckMetadata', 'isSimpleQAReport'],


    setup(props) {
        const sectionSortKey = ['General information', 'Operational Presentation', 'Financial Performance', 'SWOT Analysis', 'Future Plans']
        const qasSortOrder = ['Question', 'Answer', 'Sources'];
        const pitchdeckMetadataReady = ref(false)


        function sortSections(obj) {
            return sortObjectByKey(obj, sectionSortKey)
        }
        function sortQAS(obj) {
            return sortObjectByKey(obj, qasSortOrder)
        }
        function sortObjectByKey(obj, sortKeys) {
            const sortedKeys = Object.keys(obj).sort((a, b) => {
                return sortKeys.indexOf(a) - sortKeys.indexOf(b);
            });
            const sortedObject = {};

            for (const key of sortedKeys) {
                sortedObject[key] = obj[key];
            }

            return sortedObject;
        }

        function addSourceUrl(source) {
            if (source.startsWith('http')) {
                return source
            }
            else if (source.includes('.pdf')) {
                return findSourceRef(source)
            }
            else {
                return ''
            }
        }
        function findSourceRef(source) {
            const filePaths = props.pitchdeckMetadata.filePaths
            const fileUrls = props.pitchdeckMetadata.fileUrls
            const zip = {};
            for (let i = 0; i < filePaths.length; i++) {
                zip[filePaths[i]] = fileUrls[i];
            }
            for (const pdfUrl of filePaths) {
                const pdfName = extractPdfName(source)
                if (pdfUrl.includes(pdfName)) {
                    return zip[pdfUrl]

                }

                else {
                    return ''
                }
            }
        }

        function extractPdfName(str) {
            // Regular expression to match the name within "<" and ".pdf>"
            let regex = /.document: (.+?)\.pdf/;

            // Execute the regular expression
            let match = regex.exec(str);

            // If there's a match, return the extracted name
            if (match && match.length > 1) {
                return match[1];
            } else {
                return ''; // Return null if no match is found
            }
        }


        watch(props.pitchdeckMetadata, async (pitchdeckMetadata) => {
            if (pitchdeckMetadata.value !== undefined) {
                pitchdeckMetadataReady.value = true


            }
        })

        return { addSourceUrl, pitchdeckMetadataReady, sortQAS, sortSections }
    }


}
</script>
<style></style>