import { ref } from 'vue'
import { projectFirestore } from '../firebase/config'

const useCollection = (collection) => {
  const isPending = ref(false)
  const error = ref(null)

  // add a new document
  const addDoc = async (doc) => {
    isPending.value = true
    error.value = null
    try {
      const res = await projectFirestore.collection(collection).add(doc)
      isPending.value =false
      return res
    }
    catch (err) {
      console.log(err.message)
      error.value = 'could not send the message'
      isPending.value = false
    }
  }

    // add a new document with custom id
    const addDocWithCustomId = async (doc, customId) => {
      isPending.value = true
      error.value = null
      try {
        const res = await projectFirestore.collection(collection).doc(customId).set(doc)
        isPending.value =false
        return res
      }
      catch (err) {
        console.log(err.message)
        error.value = 'could not send the message'
        isPending.value = false
      }
    }

  return { error, addDoc, addDocWithCustomId, isPending}

}

export default useCollection