// SquareComposable.js
import Delta from "quill-delta";

export function transformJson(jsonObject) {
  // Create a ref to hold the initial value
  var obj = JSON.parse(JSON.stringify(jsonObject))
  // console.log("JSON", obj)
  var deltaObjectContent = { ops: [] }

  for (const [key1, value1] of Object.entries(obj)) {
    deltaObjectContent.ops.push({ insert: '\n' },)
    deltaObjectContent.ops.push({ insert: key1, attributes: { bold: true } })
    deltaObjectContent.ops.push({ insert: '\n', attributes: { header: 1 } },)
    for (const [key2, value2] of Object.entries(value1)) {
      deltaObjectContent.ops.push({ insert: key2, attributes: { bold: true } })
      deltaObjectContent.ops.push({ insert: '\n', attributes: { header: 3 } },)
      for (const [key3, value3] of Object.entries(sortObjectByKey(value2))) {
        if (key3 === 'Answer') {
          deltaObjectContent.ops.push({ insert: value3 })
          deltaObjectContent.ops.push({ insert: '\n' })
        }
        else if (key3 === 'Question') {
          deltaObjectContent.ops.push({ insert: key3, attributes: { bold: true, color: '#9ea2aa' } })
          deltaObjectContent.ops.push({ insert: '\n' })
          deltaObjectContent.ops.push({ insert: value3, attributes: { color: '#9ea2aa', italic: true } });
          deltaObjectContent.ops.push({ insert: '\n' });
        }
        else if (key3 === 'Sources' && value3) {
          deltaObjectContent.ops.push({ insert: key3, attributes: { bold: true, color: '#9ea2aa' } })
          deltaObjectContent.ops.push({ insert: '\n' })
          if (value3 instanceof String) {
            transformSources(value3);
          }
          else if (value3 instanceof Array) {
            // console.log("VALUE3", value3)
            for (let source of value3) {
             //  console.log("SOURCE", source)
              transformSources(source);
            }
          }
        }
      }
    }
  }

  // console.log("DELTA", deltaObjectContent)
  return new Delta(deltaObjectContent)

  function transformSources(value) {
    if (value.startsWith("https")) {
      var urls = splitUrlSourcesToList(value);
      for (const url of urls) {
        deltaObjectContent.ops.push({ insert: url, attributes: { color: '#9ea2aa', italic: true, link: url } });
      }
      deltaObjectContent.ops.push({ insert: '\n' });
    }
    else {
      deltaObjectContent.ops.push({ insert: value, attributes: { color: '#9ea2aa', italic: true } });
      deltaObjectContent.ops.push({ insert: '\n' });
    }
  }
}

export function sortObjectByKey(obj) {
  const sortedKeys = Object.keys(obj).sort();
  const sortedObject = {};

  for (const key of sortedKeys) {
    sortedObject[key] = obj[key];
  }

  return sortedObject;
}

function splitUrlSourcesToList(sources) {
  // Split the string using 'https://' as the delimiter
  const urlArray = sources.split('https://');

  // Filter out empty strings and prepend 'https://' to each URL
  const cleanedUrls = urlArray
    .filter(url => url.trim() !== '')
    .map(url => 'https://' + url);

  return cleanedUrls;
}
