<template>
    <header class="flex items-center justify-between border-b border-gray-50 px-4 py-4 sm:px-6 sm:py-6 lg:px-8">
        <h1 class="text-3xl font-bold leading-7 text-gray-900 ">News</h1>

    </header>

        <ul role="list" class="divide-y divide-white/5">
          <li v-for="item in items" :key="item.guid" class="px-4 py-4 sm:px-6 lg:px-8 hover:bg-gray-50">
            <a :href="item.link" target="_blank">
                <div class="flex items-center gap-x-3">
                  <img :src="item.thumbnail" alt="" class="h-6 w-6 flex-none rounded-full bg-gray-800" />
                  <h3 class="flex-auto truncate text-sm font-semibold leading-6 text-gray-900">{{ item.title }}</h3>
                  <time :datetime="item.pubDate" class="flex-none text-xs text-gray-600">{{ item.pubDate }}</time>
                </div>
                <p class="mt-3  text-sm text-gray-500">
                <p class="line-clamp-3">{{ item.description }}</p>
                </p>
            </a>
          </li>
          
        </ul>

    
</template>

<script setup>
import { onMounted, ref } from 'vue';


onMounted(() => {
    fetchData()
})
const techcrunch_thunbnail = "https://techcrunch.com/wp-content/uploads/2015/02/cropped-cropped-favicon-gradient.png?w=32"
const items = ref([])
var feed = "https://api.rss2json.com/v1/api.json?rss_url=https://wired.com/feed/rss"
async function fetchData() {
   
    var response = await fetch(feed)
    var body = await response.json()
    items.value=body.items
}
const activityItems = [
    {
        user: {
            name: 'Michael Foster',
            imageUrl:
                'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
        projectName: 'ios-app',
        commit: '2d89f0c8',
        branch: 'main',
        date: '1h',
        dateTime: '2023-01-23T11:00',
    },
    {
        user: {
            name: 'Michael Foster',
            imageUrl:
                'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
        projectName: 'ios-app',
        commit: '2d89f0c8',
        branch: 'main',
        date: '1h',
        dateTime: '2023-01-23T11:00',
    }
    // More items...
]



const newsArticles = [{
    date: "2021-11-16T00:00:00",
    content: "AURA",
    link: "/organization/aura-dae7"
},
{
    date: "2022-09-28T00:00:00",
    content: " LinkedIn Top Startups 2022: The 10 South African companies on the rise ",
    link: "https://www.linkedin.com/pulse/linkedin-top-startups-2022-10-south-african-companies-/"
}
]
</script>

<style lang="scss" scoped></style>